import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  CopyIcon,
  Cross2Icon,
  DotsHorizontalIcon,
  DrawingPinFilledIcon,
  DrawingPinIcon,
  OpenInNewWindowIcon,
  PlusIcon,
  Share2Icon,
} from '@radix-ui/react-icons';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  DropdownMenu,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  Separator,
  Strong,
  Switch,
  Text,
  TextField,
  Theme,
} from '@scale/scaleui-radix';
import * as React from 'react';
import { Marker } from './Marker';
import { allPeople, email } from './people';

type ExampleLayoutProps = React.ComponentPropsWithoutRef<typeof Flex> & {
  focusable?: boolean;
};

export function ExampleThemesDashboard({ focusable = true, ...props }: ExampleLayoutProps) {
  // We’ll use a different portal container for homepage demo purposes; this is usually not needed.
  const [portalContainer, setPortalContainer] = React.useState<HTMLDivElement | null>(null);

  // Interactive elements may be not focusable for homepage demo purposes
  const tabIndex = focusable ? undefined : -1;

  // Simple state to make the example functional
  const [state, setState] = React.useState({
    todo: [
      { id: 'a', completed: false },
      { id: 'b', completed: false },
      { id: 'c', completed: false },
      { id: 'd', completed: false },
      { id: 'e', completed: true },
      { id: 'f', completed: true },
    ],
    activityPinned: true,
    financePinned: false,
  });

  return (
    <Flex align="center" gap="6" ref={setPortalContainer} {...props}>
      <Flex direction="column" flexShrink="0" gap="6" width="640px">
        <Card size="4">
          <Heading as="h3" mb="2" size="6" trim="start">
            Gen AI team
          </Heading>

          <Text as="p" color="gray" mb="5" size="2">
            Invite and manage your team members.
          </Text>

          <Flex gap="3" mb="5">
            <Box flexGrow="1">
              <TextField.Root placeholder="Email address" size="2" tabIndex={tabIndex} />
            </Box>
            <Button size="2" tabIndex={tabIndex}>
              Invite
            </Button>
          </Flex>

          <Flex direction="column">
            {[4, 2, 12, 20, 16].map((number, i) => (
              <Box key={number}>
                <Flex align="center" gap="4">
                  <Flex align="center" gap="3" width="200px">
                    <Avatar
                      fallback={allPeople[number]?.name[0].toUpperCase()}
                      src={allPeople[number]?.image}
                    />
                    <Link
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                      }}
                      size="2"
                      tabIndex={tabIndex}
                      wrap="nowrap"
                    >
                      {allPeople[number]?.name}
                    </Link>
                  </Flex>

                  <Text color="gray" size="2">
                    {email(allPeople[number]?.name)}
                  </Text>

                  <Flex flexGrow="1" justify="end">
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger>
                        <IconButton color="gray" tabIndex={tabIndex} variant="ghost">
                          <DotsHorizontalIcon />
                        </IconButton>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content container={portalContainer} variant="soft">
                        <DropdownMenu.Item>View profile</DropdownMenu.Item>
                        <DropdownMenu.Item>Change role</DropdownMenu.Item>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item color="red">Remove</DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </Flex>
                </Flex>

                {i !== 4 && (
                  <Box>
                    <Separator my="3" size="4" />
                  </Box>
                )}
              </Box>
            ))}
          </Flex>
        </Card>

        <Card size="4">
          <Heading as="h3" mb="2" size="6" trim="start">
            Notifications
          </Heading>

          <Text as="p" color="gray" mb="6" size="2">
            Manage your notification settings.
          </Text>

          <Box>
            <Separator my="5" size="4" />
          </Box>

          <Flex direction="column">
            <Flex align="start" gap="9" justify="between">
              <Box>
                <Heading as="h4" mb="1" size="3">
                  Comments
                </Heading>
                <Text as="p" color="gray" size="2">
                  Receive notifications when someone comments on your documents or mentions you.
                </Text>
              </Box>
              <Flex direction="column" gap="4" mt="1">
                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch defaultChecked tabIndex={tabIndex} />
                    <Text>Push</Text>
                  </Text>
                </Flex>

                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch defaultChecked tabIndex={tabIndex} />
                    <Text>Email</Text>
                  </Text>
                </Flex>

                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch tabIndex={tabIndex} />
                    <Text>Slack</Text>
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Box>
              <Separator my="5" size="4" />
            </Box>

            <Flex align="start" gap="9" justify="between">
              <Box>
                <Heading as="h4" mb="1" size="3">
                  Favorites
                </Heading>
                <Text as="p" color="gray" size="2">
                  Receive notifications when there is activity related to your favorite items.
                </Text>
              </Box>
              <Flex direction="column" gap="4" mt="1">
                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch defaultChecked tabIndex={tabIndex} />
                    <Text>Push</Text>
                  </Text>
                </Flex>

                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch defaultChecked tabIndex={tabIndex} />
                    <Text>Email</Text>
                  </Text>
                </Flex>

                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch tabIndex={tabIndex} />
                    <Text>Slack</Text>
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Box>
              <Separator my="5" size="4" />
            </Box>

            <Flex align="start" gap="9" justify="between">
              <Box>
                <Heading as="h4" mb="1" size="3">
                  New documents
                </Heading>
                <Text as="p" color="gray" size="2">
                  Receive notifications whenever people on your team create new documents.
                </Text>
              </Box>
              <Flex direction="column" gap="4" mt="1">
                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch defaultChecked tabIndex={tabIndex} />
                    <Text>Push</Text>
                  </Text>
                </Flex>

                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch defaultChecked tabIndex={tabIndex} />
                    <Text>Email</Text>
                  </Text>
                </Flex>

                <Flex asChild gap="2">
                  <Text as="label" size="2" weight="medium">
                    <Switch tabIndex={tabIndex} />
                    <Text>Slack</Text>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card size="4">
          <Heading as="h3" mb="2" size="6" trim="start">
            Pricing
          </Heading>

          <Text as="p" color="gray" mb="5" size="2">
            No credit card required. Every plan includes a 30-day trial of all Pro features.
          </Text>

          <Grid columns="3" gap="6">
            <Flex direction="column">
              <Text mb="1" size="5" weight="medium">
                Basic
              </Text>
              <Text color="gray" mb="4" size="2">
                3 team members
              </Text>
              <Text mb="4" size="5" weight="medium">
                $0
                <Text size="5" style={{ color: 'var(--gray-a8)' }} weight="medium">
                  {' / mo'}
                </Text>
              </Text>

              <Flex direction="column" gap="2">
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Expense tracking</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Invoicing</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Payment tracking</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Transaction recording</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Basic reports</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Email support</Text>
                </Flex>
                <Button mt="3" tabIndex={tabIndex} variant="outline">
                  Downgrade
                </Button>
              </Flex>
            </Flex>

            <Flex direction="column">
              <Text mb="1" size="5" weight="medium">
                Growth
              </Text>
              <Text color="gray" mb="4" size="2">
                10 team members
              </Text>
              <Text mb="4" size="5" weight="medium">
                $49
                <Text size="5" style={{ color: 'var(--gray-a8)' }} weight="medium">
                  {' / mo'}
                </Text>
              </Text>

              <Flex direction="column" gap="2">
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Online payments</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Recurring invoices</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Bill management</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Inventory tracking</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Detailed reports</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Phone support</Text>
                </Flex>
                <Button mt="3" tabIndex={tabIndex} variant="outline">
                  Go to Billing
                </Button>
              </Flex>
            </Flex>

            <Flex direction="column">
              <Text mb="1" size="5" weight="medium">
                Pro
              </Text>
              <Text color="gray" mb="4" size="2">
                Unlimited team members
              </Text>
              <Text mb="4" size="5" weight="medium">
                $99
                <Text size="5" style={{ color: 'var(--gray-a8)' }} weight="medium">
                  {' / mo'}
                </Text>
              </Text>

              <Flex direction="column" gap="2">
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Custom invoices</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Multi-business</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Team collaboration</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">App integrations</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Advanced security</Text>
                </Flex>
                <Flex align="center" gap="2">
                  <Marker>
                    <CheckIcon height="14" width="14" />
                  </Marker>
                  <Text size="2">Priority support</Text>
                </Flex>
                <Button mt="3" tabIndex={tabIndex}>
                  Upgrade
                </Button>
              </Flex>
            </Flex>
          </Grid>
        </Card>
      </Flex>

      <Flex direction="column" flexShrink="0" gap="6" width="416px">
        <Card size="4">
          <Heading as="h3" mb="5" size="6" trim="start">
            Scale sign up
          </Heading>

          <Box mb="5">
            <Flex mb="1">
              <Text as="label" size="2" weight="medium">
                Email address
              </Text>
            </Flex>
            <TextField.Root
              id="example-email-field"
              placeholder="Enter your email"
              tabIndex={tabIndex}
            />
          </Box>

          <Box mb="5" position="relative">
            <Flex align="baseline" justify="between" mb="1">
              <Text as="label" size="2" weight="medium">
                Password
              </Text>
              <Link
                href="#"
                onClick={e => {
                  e.preventDefault();
                }}
                size="2"
                tabIndex={tabIndex}
              >
                Forgot password?
              </Link>
            </Flex>
            <TextField.Root
              id="example-password-field"
              placeholder="Enter your password"
              tabIndex={tabIndex}
            />
          </Box>

          <Flex gap="3" justify="end" mt="6">
            <Button tabIndex={tabIndex} variant="outline">
              Create an account
            </Button>
            <Button tabIndex={tabIndex}>Sign in</Button>
          </Flex>
        </Card>

        <Card size="4">
          <Box m="3" position="absolute" right="0" top="0">
            <IconButton color="gray" tabIndex={tabIndex} variant="ghost">
              <Cross2Icon height="20" width="20" />
            </IconButton>
          </Box>

          <Heading as="h3" mb="2" size="6" trim="start">
            Your company card
          </Heading>

          <Text as="p" color="gray" mb="6" size="2">
            View and manage your corporate card.
          </Text>

          <Box
            p="6"
            style={{
              backgroundColor: 'var(--gray-a3)',
              borderRadius: 'var(--radius-4)',
            }}
          >
            <Theme appearance="dark" asChild>
              <Flex
                direction="column"
                height="168px"
                justify="between"
                style={
                  {
                    background: 'linear-gradient(to top right, var(--accent-9), #E18BFF)',
                    boxShadow: '0 1px 20px -5px #7971E9AA',
                    borderRadius: 'var(--radius-3)',
                    '--gray-12': 'white',
                  } as React.CSSProperties
                }
              >
                <Text mt="3" mx="3" size="2" weight="medium">
                  Alex Wang
                </Text>
                <Box>
                  <Flex align="center" gap="3" mb="1" mx="3">
                    <Text size="2">
                      4929 3849
                      {/* An empty span prevents iOS Safari from thinking it's a telephone number */}
                      <span> </span>
                      5027 1846
                    </Text>
                    <IconButton color="gray" size="1" tabIndex={tabIndex} variant="ghost">
                      <CopyIcon />
                    </IconButton>
                  </Flex>
                  <Flex gap="3" mb="2" mx="3">
                    <Text size="2">01 / 27</Text>
                    <Text size="2">999</Text>
                  </Flex>
                </Box>
              </Flex>
            </Theme>
          </Box>

          <Flex gap="3" justify="end" mt="6">
            <Button color="red" tabIndex={tabIndex} variant="outline">
              Freeze
            </Button>
            <Button tabIndex={tabIndex}>Done</Button>
          </Flex>
        </Card>

        <Card size="4">
          <Box m="3" position="absolute" right="0" top="0">
            <IconButton color="gray" tabIndex={tabIndex} variant="ghost">
              <Cross2Icon height="20" width="20" />
            </IconButton>
          </Box>

          <Flex align="center" direction="column" gap="3">
            <Marker height="48px" width="48px">
              <CheckIcon height="32" width="32" />
            </Marker>

            <Heading as="h3" mb="2" size="6">
              Invoice paid
            </Heading>
          </Flex>

          <Text align="center" as="p" mb="5" size="3">
            You paid $17,975.30. A receipt copy was sent to <Strong>accounting@example.com</Strong>
          </Text>

          <Flex align="stretch" direction="column" gap="3">
            <Button tabIndex={tabIndex}>Next invoice</Button>

            <Button tabIndex={tabIndex} variant="outline">
              Done
            </Button>
          </Flex>
        </Card>

        <Card size="4">
          <Box m="3" position="absolute" right="0" top="0">
            <IconButton color="gray" tabIndex={tabIndex} variant="ghost">
              <Cross2Icon height="20" width="20" />
            </IconButton>
          </Box>

          <Heading as="h3" mb="5" size="6" trim="start">
            Invoice{' '}
            <Link
              href="#"
              onClick={e => {
                e.preventDefault();
              }}
              tabIndex={tabIndex}
              weight="medium"
            >
              #3463
            </Link>
          </Heading>

          <Grid columns="2" gapX="4" gapY="5">
            <Box>
              <Text as="div" color="gray" mb="1" size="2">
                Issued
              </Text>
              <Text as="div" size="3" weight="medium">
                June 21, 2023
              </Text>
            </Box>

            <Box>
              <Text as="div" color="gray" mb="1" size="2">
                Due
              </Text>
              <Text as="div" size="3" weight="medium">
                July 21, 2023
              </Text>
            </Box>

            <Box>
              <Text as="div" color="gray" mb="1" size="2">
                To
              </Text>
              <Text as="div" mb="1" size="3" weight="medium">
                Paradise Ventures
              </Text>
              <Text as="div" size="2">
                742 Evergreen Terrace, Springfield, IL 62704
              </Text>
            </Box>

            <Box>
              <Text as="div" color="gray" mb="1" size="2">
                From
              </Text>
              <Text as="div" mb="1" size="3" weight="medium">
                Rogue Widgets
              </Text>
              <Text as="div" size="2">
                1600 Baker Street NW, Washington, DC 20500
              </Text>
            </Box>

            <Flex direction="column" gap="1" gridColumn="1 / -1">
              <Flex justify="between">
                <Text color="gray" mb="1" size="2">
                  Services
                </Text>
                <Text color="gray" mb="1" size="2">
                  Price
                </Text>
              </Flex>
              <Flex justify="between">
                <Text mb="1" size="3" weight="medium">
                  Branding
                </Text>
                <Text size="2">$20,000</Text>
              </Flex>
              <Flex justify="between">
                <Text mb="1" size="3" weight="medium">
                  Marketing website
                </Text>
                <Text size="2">$17,500</Text>
              </Flex>
              <Box>
                <Separator mb="2" mt="1" size="4" />
              </Box>
              <Flex justify="between">
                <Text size="2">Total</Text>
                <Text size="2">$38,500</Text>
              </Flex>
            </Flex>
          </Grid>

          <Flex gap="3" justify="end" mt="6">
            <Button color="red" tabIndex={tabIndex} variant="outline">
              Reject
            </Button>
            <Button tabIndex={tabIndex}>Approve</Button>
          </Flex>
        </Card>
      </Flex>

      <Flex direction="column" flexShrink="0" gap="6" width="640px">
        <Card size="4">
          <Heading as="h3" mb="2" size="6" trim="start">
            Financial performance
          </Heading>

          <Flex m="3" position="absolute" right="0" top="0">
            <IconButton color="gray" style={{ margin: 0 }} tabIndex={tabIndex} variant="ghost">
              <OpenInNewWindowIcon height="20" width="20" />
            </IconButton>

            <IconButton
              color="gray"
              onClick={() => {
                setState(state => ({ ...state, financePinned: !state.financePinned }));
              }}
              style={{ margin: 0 }}
              tabIndex={tabIndex}
              variant={state.financePinned ? 'soft' : 'ghost'}
            >
              {state.financePinned ? (
                <DrawingPinFilledIcon height="20" width="20" />
              ) : (
                <DrawingPinIcon height="20" width="20" />
              )}
            </IconButton>
          </Flex>

          <Text as="p" color="gray" mb="6" size="2">
            Review your company’s KPIs compared to the month before.
          </Text>

          <Grid columns="3" gap="5">
            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  MRR
                </Text>
                <Badge color="teal" radius="full">
                  <ArrowUpIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  3.2%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                $350K
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  OpEx
                </Text>
                <Badge color="red" radius="full">
                  <ArrowUpIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  12.8%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                $211K
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  CapEx
                </Text>
                <Badge color="teal" radius="full">
                  <ArrowDownIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  8.8%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                $94K
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  GPM
                </Text>
                <Badge color="red" radius="full">
                  <ArrowDownIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  1.2%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                44.6%
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  NPM
                </Text>
                <Badge color="gray" radius="full" variant="surface">
                  0.0%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                9.1%
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  EBITDA
                </Text>
                <Badge color="teal" radius="full">
                  <ArrowUpIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  4.1%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                $443K
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  CAC
                </Text>
                <Badge color="teal" radius="full">
                  <ArrowDownIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  11.0%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                $146
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  LTV
                </Text>
                <Badge color="teal" radius="full">
                  <ArrowUpIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  3%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                $1,849
              </Text>
            </Box>

            <Box>
              <Flex align="center" gap="2" mb="2">
                <Text color="gray" size="2">
                  Churn
                </Text>
                <Badge color="red" radius="full">
                  <ArrowUpIcon height="12" style={{ marginLeft: -2 }} width="12" />
                  1.1%
                </Badge>
              </Flex>
              <Text as="div" mb="2" size="8" weight="medium">
                12.4%
              </Text>
            </Box>
          </Grid>
        </Card>

        <Card size="4">
          <Heading as="h3" mb="2" size="6" trim="start">
            Recent activity
          </Heading>

          <Flex m="3" position="absolute" right="0" top="0">
            <IconButton color="gray" style={{ margin: 0 }} tabIndex={tabIndex} variant="ghost">
              <OpenInNewWindowIcon height="20" width="20" />
            </IconButton>

            <IconButton
              color="gray"
              onClick={() => {
                setState(state => ({ ...state, activityPinned: !state.activityPinned }));
              }}
              style={{ margin: 0 }}
              tabIndex={tabIndex}
              variant={state.activityPinned ? 'soft' : 'ghost'}
            >
              {state.activityPinned ? (
                <DrawingPinFilledIcon height="20" width="20" />
              ) : (
                <DrawingPinIcon height="20" width="20" />
              )}
            </IconButton>
          </Flex>

          <Text as="p" color="gray" mb="7" size="2">
            Review what has happened over the past days.
          </Text>

          <Flex direction="column">
            <Flex direction="column" gap="3" mb="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[6]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[6].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[6].name}
                    </Text>
                    <Text as="div" color="gray" size="2">
                      Approved invoice{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        #3461
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 21, 11:34 am
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" my="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[8]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[8].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[8].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Purchased{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        15 office chairs
                      </Link>{' '}
                      and{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        2 drum sets
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 21, 9:43 am
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" my="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[8]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[8].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[8].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Responded to your comment{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        #7514
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 21, 9:41 am
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" my="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[28]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[28].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[28].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Created{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        4 invoices
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 20, 4:55 pm
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" my="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[26]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[26].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[26].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Updated client details for{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        Acme Co.
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 20, 3:30 pm
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" my="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[25]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[25].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[25].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Created{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        a new report
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 20, 3:22 pm
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" my="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[25]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[25].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[25].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Deleted report{' '}
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                        }}
                        tabIndex={tabIndex}
                      >
                        #34
                      </Link>
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 20, 1:00 pm
                </Text>
              </Flex>
            </Flex>

            <Box>
              <Separator size="4" />
            </Box>

            <Flex direction="column" gap="3" mt="5">
              <Flex align="center" justify="between">
                <Flex align="center" gap="3">
                  <Avatar
                    fallback={allPeople[20]?.name[0].toUpperCase()}
                    size="3"
                    src={allPeople[20].image}
                  />
                  <Box>
                    <Text as="div" size="2" weight="medium">
                      {allPeople[20].name}
                    </Text>
                    <Text as="p" color="gray" size="2">
                      Joined the team
                    </Text>
                  </Box>
                </Flex>

                <Text color="gray" size="2">
                  June 20, 12:47 pm
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        <Card size="4">
          <Heading as="h3" mb="2" size="6" trim="start">
            To-do
          </Heading>

          <Flex gap="3" m="3" position="absolute" right="0" top="0">
            <IconButton color="gray" tabIndex={tabIndex} variant="ghost">
              <Share2Icon height="20" width="20" />
            </IconButton>
            <IconButton color="gray" tabIndex={tabIndex} variant="ghost">
              <PlusIcon height="20" width="20" />
            </IconButton>
          </Flex>

          <Text as="p" color="gray" mb="5" size="2">
            Stay on top of your daily tasks.
          </Text>

          <ToDoList
            focusable={focusable}
            items={state.todo}
            onItemsChange={items => {
              setState({ ...state, todo: items });
            }}
          />
        </Card>
      </Flex>
    </Flex>
  );
}

type ToDoItem = {
  id: string;
  completed: boolean;
};

type ToDoList = {
  focusable: boolean;
  items: ToDoItem[];
  onItemsChange: (items: ToDoItem[]) => void;
};

function ToDoList({ focusable, items, onItemsChange }: ToDoList) {
  const tabIndex = focusable ? undefined : -1;
  const itemsContent = {
    a: (
      <span>
        Respond to comment{' '}
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
          tabIndex={tabIndex}
          underline="hover"
        >
          #384
        </Link>{' '}
        from Travis Ross
      </span>
    ),
    b: (
      <span>
        Invite{' '}
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
          tabIndex={tabIndex}
          underline="hover"
        >
          Acme Co.
        </Link>{' '}
        team to Slack
      </span>
    ),
    c: (
      <span>
        Create a report{' '}
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
          tabIndex={tabIndex}
          underline="hover"
        >
          requested
        </Link>{' '}
        by Jeeyoon Hyun
      </span>
    ),
    d: (
      <span>
        Review support request{' '}
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
          tabIndex={tabIndex}
          underline="hover"
        >
          #85
        </Link>
      </span>
    ),
    e: <span>Close Q2 finances</span>,
    f: (
      <span>
        Review invoice{' '}
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
          tabIndex={tabIndex}
          underline="hover"
        >
          #3456
        </Link>
      </span>
    ),
  };

  return (
    <Flex direction="column" gap="2">
      {items.map(item => (
        <Text as="label" key={item.id} size="2">
          <Flex as="span" gap="2">
            <Checkbox
              checked={item.completed}
              onCheckedChange={checked => {
                const newItems = items.slice();
                const newItem = newItems.find(candidate => candidate.id === item.id);
                if (newItem) {
                  newItem.completed = Boolean(checked);
                  onItemsChange(newItems);
                }
              }}
              tabIndex={tabIndex}
            />
            <Text
              color={item.completed ? 'gray' : undefined}
              style={
                {
                  textDecoration: item.completed ? 'line-through' : undefined,
                  '--accent-12': 'var(--accent-11)',
                } as React.CSSProperties
              }
            >
              {itemsContent[item.id]}
            </Text>
          </Flex>
        </Text>
      ))}
    </Flex>
  );
}
